import React from 'react'
import { Row, Col, Button } from 'antd'
import { CameraOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import Loader from 'components/LayoutComponents/Loader'
import actions from 'redux/virtual/photobooth/actions'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Webcam from 'react-webcam'

const settings = {
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
  ],
}

// const overlays = {
//   1: {
//     imagePath: 'resources/images/overlay/overlay01.png',
//     thumbnail: 'resources/images/overlay/overlay01.png',
//   },
// 2: {
//   imagePath: 'resources/images/overlay/overlay02.png',
//   thumbnail: 'resources/images/overlay/overlay02.png',
// },
// 3: {
//   imagePath: 'resources/images/overlay/overlay03.png',
//   thumbnail: 'resources/images/overlay/overlay03.png',
// },
// 4: {
//   imagePath: 'resources/images/overlay/overlay04.png',
//   thumbnail: 'resources/images/overlay/overlay04.png',
// },
// 5: {
//   imagePath: 'resources/images/overlay/overlay05.png',
//   thumbnail: 'resources/images/overlay/overlay05.png',
// },
// }

const mapStateToProps = ({ photobooth }) => ({ photobooth })
/* eslint-disable react/destructuring-assignment, react/no-access-state-in-setstate,no-plusplus,jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */
@connect(mapStateToProps)
class Capture extends React.Component {
  state = {
    countdown: 3,
    startCountdown: false,
    overlayApplied: false,
    currentoverlay: "",
  }

  componentDidMount() {
    const {
      photobooth: { photoboothData: { overlays } },
    } = this.props;
    if (overlays.length > 0) {
      this.setState({ overlayApplied: true, currentoverlay: overlays[0] })
    }
  }

  setRef = webcam => {
    this.webcam = webcam
  }

  applyOverlay = event => {
    const index = event.target.getAttribute('data-index')
    this.setState({ overlayApplied: true, currentoverlay: index })
  }

  dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(',')
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }

    return new File([u8arr], filename, { type: mime })
  }

  capture = () => {
    const {
      dispatch,
      photobooth: { currentStep },
    } = this.props;
    const { countdown, currentoverlay } = this.state;

    this.setState({ startCountdown: true });

    const countdownInterval = setInterval(() => {
      this.setState((prevState) => ({ countdown: prevState.countdown - 1 }), () => {
        if (this.state.countdown <= 0) {
          clearInterval(countdownInterval);
        }
      });
    }, 1000);

    setTimeout(() => {
      const imageSrc = this.webcam.getScreenshot();
      const canvas = document.createElement('canvas');
      canvas.id = 'overlayImage';
      canvas.width = 600;
      canvas.height = 600;
      const ctx = canvas.getContext('2d');

      const image = new Image();
      image.crossOrigin = 'Anonymous';
      image.src = imageSrc;
      image.onload = () => {
        ctx.drawImage(image, 0, 0);

        const overlayImage = new Image();
        overlayImage.crossOrigin = 'Anonymous';
        overlayImage.src = currentoverlay;
        overlayImage.onload = () => {
          ctx.drawImage(overlayImage, 0, 0);

          try {
            const finalImage = canvas.toDataURL();
            dispatch({
              type: actions.SET_STATE,
              payload: {
                imageSrc: finalImage,
                currentStep: currentStep + 1,
                webcamLoading: true,
              },
            });

            const imgBlob = this.dataURLtoFile(finalImage, `${Date.now()}photobooth.png`);
            dispatch({
              type: actions.UPLOAD_PHOTOBOOTH_IMAGE,
              payload: {
                imageString: imgBlob,
              },
            });
          } catch (e) {
            console.error('SecurityError:', e);
          }
        };

        overlayImage.onerror = (e) => {
          console.error('Failed to load overlay image', e);
        };
      };

      image.onerror = (e) => {
        console.error('Failed to load webcam image', e);
      };
    }, countdown * 1000);
  };


  disableLoader = () => {
    const { dispatch } = this.props
    dispatch({
      type: actions.SET_STATE,
      payload: {
        webcamLoading: false,
      },
    })
  }

  render() {
    const {
      photobooth: { webcamLoading, photoboothData: { overlays } },

    } = this.props
    console.log('overlays', overlays);
    const { countdown, startCountdown, currentoverlay, overlayApplied } = this.state
    const videoConstraints = {
      width: 600,
      height: 600,
      facingMode: 'user',
    }
    const slides = overlays.map(url => {
      return (
        <div>
          <img
            src={url}
            alt={`overlay${url}`}
            data-index={url}
            onClick={this.applyOverlay}
            style={{ width: '100%' }}
          />
        </div>
      )
    })
    return (
      <>
        <Row justify="space-around" align="middle" className="fullHeightScreen">
          <Col span={14} className="text-left">
            {webcamLoading ? <Loader /> : ''}
            {startCountdown ? <h2 className="countdown">{countdown}</h2> : ''}
            {overlayApplied ? (
              <img
                src={currentoverlay}
                alt="overlayImage"
                className="overlayimage"
              />
            ) : (
              ''
            )}
            <Webcam
              audio={false}
              imageSmoothing
              mirrored
              style={{
                width: '80%',
                // height: "calc(100vh - 50px)",
                borderRadius: '20px 20px 20px 20px',
              }}
              ref={this.setRef}
              minScreenshotWidth={600}
              minScreenshotHeight={600}
              screenshotQuality={1}
              screenshotFormat="image/jpg"
              onUserMedia={this.disableLoader}
              videoConstraints={videoConstraints}
            />
          </Col>
          <Col span={10} className="text-center">
            <Row justify="space-around" align="middle">
              <Col span={24} className="text-center">
                <Button
                  type="primary"
                  shape="circle"
                  onClick={this.capture}
                  icon={<CameraOutlined />}
                  size="large"
                />
                <br />
                <br />
                <h5 className="white-text">Tap to snap</h5>
              </Col>
            </Row>
            <br />
            <br />
            <br />
            <br />
            <Row justify="space-around" align="bottom">
              <Col span={2} />
              <Col span={20} className="containerSlider">
                <h4 style={{ color: 'white' }}>Select Overlay</h4>
                <Slider {...settings}>{slides}</Slider>
              </Col>
              <Col span={2} />
            </Row>
          </Col>
        </Row>
        {/* <Row justify="space-around" align="middle" style={{ marginTop: '15px' }}>
          <Col span={24} className="text-center">
            <h3>Take a selfie</h3>
          </Col>
        </Row>
        <Row justify="space-around" align="middle" style={{ marginTop: '15px' }}>
          <Col span={24} className="text-center">
            <Button
              type="primary"
              shape="circle"
              onClick={this.capture}
              icon={<CameraOutlined />}
              size="large"
            />
          </Col>
        </Row> */}
      </>
    )
  }
}

export default Capture
