import React from 'react'
import { connect } from 'react-redux'
import ReactPlayer from 'react-player'
import { Button, Tooltip } from 'antd'
import { PauseOutlined, CaretRightFilled } from '@ant-design/icons'

/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
const mapStateToProps = ({ contest, virtualEvent }) => ({ contest, virtualEvent })
@connect(mapStateToProps)
class VideoShowcase extends React.Component {
  state = {
    askedtoplay: false,
    videoWidth: 0,
    videoHeight: 0,
  }

  playVideo = () => {
    const { url } = this.props
    const theVideo = document.createElement('video')
    theVideo.src = url
    theVideo.load()
    theVideo.onloadedmetadata = () => {
      this.setState({
        videoHeight: theVideo.videoHeight,
        videoWidth: theVideo.videoWidth,
        askedtoplay: true,
      })
    }
  }

  stopVideo = () => {
    this.setState({ askedtoplay: false })
  }

  // componentDidUpdate = () =>{
  //   const {askedtoplay} = this.state

  // }

  render() {
    const {
      url,
      thumbnailurl,
      virtualEvent: {
        dimensions: { height },
      },
    } = this.props
    const { askedtoplay, videoWidth, videoHeight } = this.state
    return (
      <>
        {thumbnailurl !== undefined &&
        thumbnailurl !== null &&
        thumbnailurl !== '' &&
        !askedtoplay ? (
          <div style={{ width: '100%', maxHeight: '65vh' }} className="text-center">
            <img
              src={thumbnailurl}
              alt="entrythumbnail"
              style={{ width: '100%', height: '100%' }}
              onClick={this.playVideo}
            />
            <Tooltip title="Play Video" position="top">
              <Button
                type="primary"
                size="large"
                shape="round"
                icon={<CaretRightFilled />}
                style={{ position: 'fixed', top: '45%', left: '45%' }}
                onClick={this.playVideo}
              />
            </Tooltip>
          </div>
        ) : (
          <div style={{ width: '100%', maxHeight: '65vh' }} className="text-center">
            <ReactPlayer
              url={url}
              // light={thumbnailurl}
              playing
              controls
              controlslist="nodownload nofullscreen noremoteplayback"
              loop
              width="100%"
              height={videoHeight > videoWidth ? `${height / 2}px` : '100%'}
              volume={1}
              style={{ backgroundColor: '0 0 0 0.5', maxHeight: '65vh' }}
            />
            <Tooltip title="Stop Video" position="top">
              <Button
                shape="round"
                icon={<PauseOutlined />}
                style={{ marginTop: '2px', marginLeft: '-2%', position: 'fixed', bottom: 0 }}
                onClick={this.stopVideo}
              />
            </Tooltip>
          </div>
        )}
      </>
    )
  }
}

export default VideoShowcase
