import React from 'react'
import { connect } from 'react-redux'
import { Steps, Button, Row, Col } from 'antd'
import actions from 'redux/virtual/meetings/actions'
import DateForm from '../DateForm'
import FinalConfirmation from '../FinalConfirmation'

const { Step } = Steps

const steps = [
  {
    title: 'Pick Slot',
    content: <DateForm />,
  },
  {
    title: 'Send Meeting Request',
    content: <FinalConfirmation />,
  },
]

@connect(({ meetings }) => ({ meetings }))
class MeetingLayout extends React.Component {
  sendMeetingRequest = () => {
    const { dispatch } = this.props
    dispatch({
      type: actions.SEND_MEETING,
    })
  }

  next() {
    const {
      dispatch,
      meetings: { currentStep },
    } = this.props
    const current = currentStep + 1
    dispatch({
      type: actions.SET_STATE,
      payload: {
        currentStep: current,
      },
    })
  }

  prev() {
    const {
      dispatch,
      meetings: { currentStep },
    } = this.props
    const current = currentStep - 1
    dispatch({
      type: actions.SET_STATE,
      payload: {
        currentStep: current,
      },
    })
  }

  render() {
    const {
      meetings: { currentStep },
    } = this.props
    return (
      <>
        <Steps current={currentStep}>
          {steps.map(item => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
        <div className="steps-content">
          <Row>
            <Col span={24}>{steps[currentStep].content}</Col>
          </Row>
        </div>
        <div className="steps-action">
          {currentStep < steps.length - 1 && (
            <Button type="primary" onClick={() => this.next()}>
              Next
            </Button>
          )}
          {currentStep === steps.length - 1 && (
            <Button type="primary" onClick={this.sendMeetingRequest}>
              Send Request
            </Button>
          )}
          {currentStep > 0 && (
            <Button style={{ margin: '0 8px' }} onClick={() => this.prev()}>
              Previous
            </Button>
          )}
        </div>
      </>
    )
  }
}

export default MeetingLayout
