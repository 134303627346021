import React from 'react'
import { connect } from 'react-redux'
import { Row, Col, Input, Card } from 'antd'
import capitalizeFirstLetters from 'capitalize-first-letters'
import moment from 'moment'
import actions from 'redux/virtual/meetings/actions'

const { TextArea } = Input

@connect(({ meetings }) => ({ meetings }))
class FinalConfirmation extends React.Component {
  inputChange = event => {
    const { dispatch } = this.props
    dispatch({
      type: actions.SET_STATE,
      payload: {
        customMessage: event.target.value,
      },
    })
  }

  render() {
    const {
      meetings: { currentUser, selectedTimeSlotString },
    } = this.props
    return (
      <>
        <Card style={{ width: '100%' }}>
          <Row justify="space-around" align="middle">
            <Col span={24} className="text-center">
              <h5>Meet {capitalizeFirstLetters(currentUser.name)} on</h5>
              <h3>{moment(selectedTimeSlotString).format('dddd, MMMM Do YYYY, h:mm:ss a')}</h3>
            </Col>
          </Row>
          <Row justify="space-around" align="middle" style={{ backgroundColor: '#f5f5f6' }}>
            <Col span={24}>
              <TextArea
                onChange={this.inputChange}
                placeholder="Type the meeting title. Describe your meeting to increase chances of approval"
                rows={2}
              />
            </Col>
          </Row>
        </Card>
      </>
    )
  }
}

export default FinalConfirmation
