import React from 'react'
import { connect } from 'react-redux'
import { CloseCircleOutlined } from '@ant-design/icons'
import { Modal, Row, Col } from 'antd'
import { Scrollbars } from 'react-custom-scrollbars'
import actions from 'redux/virtual/meetings/actions'
import MeetingLayout from './MeetingLayout'
import './meetings.less'

/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
@connect(({ meetings }) => ({ meetings }))
class Meetings extends React.Component {
  handleClose = () => {
    const { dispatch } = this.props
    dispatch({
      type: actions.SET_STATE,
      payload: {
        isMeetingWindowVisible: false,
      },
    })
  }

  render() {
    const {
      meetings: { isMeetingWindowVisible },
    } = this.props
    return (
      <>
        <Modal
          visible={isMeetingWindowVisible}
          style={{
            top: '0px',
            maxHeight: 'calc(100vh - 180px)',
          }}
          wrapClassName="downloadModal meetingsModal"
          width="60vmax"
          onOk={this.handleClose}
          confirmLoading
          onCancel={this.handleClose}
          footer={null}
          closeIcon={<CloseCircleOutlined style={{ color: '#fff', fontSize: '25px' }} />}
          destroyOnClose
        >
          <Row id="modalHeader" className="modalHeader">
            <Col span={24} className="text-center">
              <h3>The Meeting Scheduler</h3>
            </Col>
          </Row>
          <Row id="modalContent">
            <Scrollbars autoHeight autoHeightMin="100px" autoHeightMax="calc(100vh - 185px)">
              <Col span={24}>
                <MeetingLayout />
              </Col>
            </Scrollbars>
          </Row>
        </Modal>
      </>
    )
  }
}

export default Meetings
