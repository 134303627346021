import React from 'react'
import { connect } from 'react-redux'
import { Menu, Dropdown, Tooltip, Row, Col } from 'antd'
import { FormattedMessage } from 'react-intl'
import Avatar from '../../../../../CleanUIComponents/Avatar'
import styles from './style.module.scss'

@connect(({ virtualUser, settings, locales }) => ({ virtualUser, settings, locales }))
class ProfileMenu extends React.Component {
  logout = () => {
    const { dispatch } = this.props
    dispatch({
      type: 'virtaluser/LOGOUT',
    })
  }

  editProfile = () => {
    const { dispatch } = this.props
    dispatch({
      type: 'virtaluser/SET_STATE',
      payload: {
        isEditProfileOpen: true,
      },
    })
  }

  openConfigurator = () => {
    const { dispatch } = this.props
    dispatch({
      type: 'stallconfigurator/SET_STATE',
      payload: {
        isStallDesignerOpen: true,
      },
    })
  }

  changeLanguage = theLanguage => {
    const { dispatch } = this.props
    dispatch({
      type: 'locales/SET_STATE',
      payload: {
        selectedLocale: theLanguage,
      },
    })
  }

  render() {
    const {
      virtualUser,
      locales: { selectedLocale, availableLocales, isLocaleSwitcher },
    } = this.props
    const menu = (
      <Menu selectable={false}>
        <Menu.Item>
          <strong>{virtualUser.name || 'Anonymous'}</strong>
          <br />
          {virtualUser.email}
        </Menu.Item>
        {/* <Menu.Item>
          <strong>Raffle Ticket Number</strong>
          <br />
          {virtualUser.raffle}
        </Menu.Item>
        <Menu.Divider /> */}
        {/* <Menu.Item onClick={this.editProfile}>
          Change Profile Picture
        </Menu.Item>
        <Menu.Divider /> */}
        {virtualUser.roles.includes('ROLE_STALL_CONFIGURATOR') ? (
          <Menu.Item onClick={this.openConfigurator}>Configure Stall</Menu.Item>
        ) : (
          ''
        )}
        <Menu.Divider />
        <Menu.Item onClick={this.logout}>
          <i className={`${styles.menuIcon} icmn-exit`} />
          <FormattedMessage id="topMenu.logout" />
        </Menu.Item>
      </Menu>
    )

    console.log('Lang selector')
    const languages = Object.entries(availableLocales).map(key => {
      console.log(key)
      return (
        <Menu.Item onClick={() => this.changeLanguage(key[0])} key={key[0]}>
          <Row>
            <Col span={5} className="text-center">
              <img src={key[1].icon} alt={key[1].name} style={{ width: '70%' }} />
            </Col>
            <Col span={1} />
            <Col span={18}>
              <p>{key[1].name}</p>
            </Col>
          </Row>
        </Menu.Item>
      )
    })
    const langSelector = (
      <Menu
        selectable
        style={{ width: 150 }}
        placement="bottomLeft"
        selectedKeys={[selectedLocale]}
      >
        {languages}
      </Menu>
    )

    const getInitials = (name) => {
      const initials = name.charAt(0);
      return initials.toUpperCase();
    };
    const avatarColor = '0e8827'
    const avatarData = getInitials(virtualUser.name)
    return (
      <>
        <Dropdown
          overlay={menu}
          trigger={['click']}
          className="pull-right"
          style={{ position: 'absolute', top: 0 }}
        >
          <div className={styles.dropdown}>
            <Avatar
              src={
                virtualUser.avatarType === 'text'
                  ? `https://via.placeholder.com/100x100.png/${virtualUser.avatarColor.substring(
                      1,
                    )}/fff?text=${virtualUser.avatarData}`
                  : `https://via.placeholder.com/100x100.png/${avatarColor.substring(
                    1,
                  )}/fff?text=${avatarData}`
              }
              border
              borderColor="white"
              size="10"
            />
          </div>
        </Dropdown>
        &nbsp;&nbsp;
        {isLocaleSwitcher ? (
          <Tooltip title="Change Language" placement="left">
            <Dropdown
              overlay={langSelector}
              trigger={['click']}
              className="pull-right"
              style={{ position: 'absolute', top: 0, marginRight: '5px' }}
            >
              <div className={styles.dropdown} style={{ marginRight: '10px' }}>
                <Avatar
                  src={availableLocales[selectedLocale].icon}
                  border
                  borderColor="white"
                  size="10"
                />
              </div>
            </Dropdown>
          </Tooltip>
        ) : (
          ''
        )}
      </>
    )
  }
}

export default ProfileMenu
