import React from 'react'
import { Button, Row, Col } from 'antd'
import { connect } from 'react-redux'
import actions from 'redux/virtual/event/actions'

const mapStateToProps = ({ virtualEvent }) => ({ virtualEvent })
@connect(mapStateToProps)
class Introduction extends React.Component {
  handleSwitch = () => {
    const {
      dispatch,
      virtualEvent: { currentWhereByStep, scene: { id } },
    } = this.props
    dispatch({
      type: actions.SET_STATE,
      payload: {
        currentWhereByStep: currentWhereByStep + 1
      },
    })

    dispatch({
      type: actions.UPDATE_USER_WIDGET_ANALYTICS,
      payload: {
        widgetid: `${id}-videocall`
      },
    })
  }

  render() {
    return (
      <>
        {/* <img src="resources/images/beforemeeting.png" width="100%" alt="Meeting Instructions" />
        <h2>Welcome to meeting</h2> */}
        <br />
        <Row>
          <Col span={9} />
          <Col span={6}>
            <img src="resources/images/logo-inverse.png" width="100%" alt="Networking Room" />
          </Col>
          <Col span={9} />
        </Row>
        <br />
        <br />
        <Row>
          <Col span={4} />
          <Col span={16}>
            <h2 className='text-white text-center'>Welcome to {process.env.REACT_APP_EVENT_NAME} meeting</h2>
          </Col>
          <Col span={4} />
        </Row>
        <br />
        <br />
        <Row>
          <Col span={4} />
          <Col span={16}>
            <ol className="white-text tableInstructions">
              <li>You are entering a group discussion call.</li>
              {/* <li>We will have a Flipkart Moderator, who will moderate this entire call.</li> */}
              <li>Please do unmute if you want to speak.</li>
              <li>Please do maintain the decorum of the call</li>
            </ol>
          </Col>
          <Col span={4} />
        </Row>
        <br />
        <br />
        <Row className="text-center">
          <Col span={9} />
          <Col span={6}>
            <Button type="primary" size="large" onClick={this.handleSwitch}>Join the call</Button>
          </Col>
          <Col span={9} />
        </Row>
        <br />
        <br />
        <br />

      </>
    )
  }
}

export default Introduction
